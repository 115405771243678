<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      pagination
      search
      :max-items="itemsPerPage"
      :data="brands"
    >
        <!-- ITEMS PER PAGE -->
  
      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              class="table-image-container"
            >
              <img :src="tr.logo" class="table-image" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="ArchiveIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                @click.stop="openConfirmRetrieve(tr.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleBrand from "@/store/brands/moduleBrand.js";
export default {
  data() {
    return {
      isMounted: false,
      itemsPerPage:5,
      totalItems:'',
      lastPage:'',
      selected_id:''
    };
  },
  computed: {
    brands() {
      return this.$store.state.brand.archived;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.brands.length;
    },
    
  },
  methods: {
    openConfirmRetrieve(id) {
      this.selected_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: 'تأكيد',
        text: ` هل انت متأكد من استعادة هذه العلامة التجارية؟`,
        accept: this.retrieveData,
        acceptText: 'نعم',
        cancelText: 'الغاء',
      });
    },
    retrieveData() {
      this.$store
        .dispatch("brand/retrieveBrand", this.selected_id)
        .then((response) => {
          this.successDialog(response.data.message);
        })
        .catch((error) => {
         this.errorDialog(error);
        });
    },
    
  },
  created() {
    if (!moduleBrand.isRegistered) {
      this.$store.registerModule("brand", moduleBrand);
      moduleBrand.isRegistered = true;
    }
    this.$store.dispatch("brand/fetchArchivedBrands");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
.table-image{
max-width:100px}
#ecommerce-wishlist-demo {
  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}
</style>
