import axios from "@/axios.js"

export default {
    fetchBrands({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`/brands`)
            .then((response)=>{
                commit('SET_BRANDS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    
    updatePercentage({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`brands/update_percentage`,payload)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchArchivedBrands({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`/brands/archive`)
            .then((response)=>{
                commit('SET_ARCHIVED_BRANDS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    createNewBrand({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`/brands`,payload)
            .then((response)=>{
                commit('ADD_NEW_BRAND',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    updateBrand({commit},payload){
        return new Promise((resolve,reject)=>{
            payload.bodyFormData.set("_method", 'put' );
            axios.post(`/brands/${payload.brand_id}`,payload.bodyFormData)
            .then((response)=>{
                commit('UPDATE_BRAND',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    archiveBrand({commit},itemId){
        return new Promise((resolve,reject)=>{
            axios.delete(`/brands/${itemId}`)
            .then((response)=>{
                commit('DELETE_BRAND',itemId);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    retrieveBrand({commit},itemId){
        return new Promise((resolve,reject)=>{
            axios.post(`/brands/restore/${itemId}`)
            .then((response)=>{
                commit('DELETE_ARCHIVED_BRAND',itemId);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },   
}