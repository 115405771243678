export default {
    SET_BRANDS(state,brands){  state.brands = brands; },
    SET_CURRENCIES(state,currencies){  state.currencies = currencies; },
    ADD_NEW_BRAND(state,brand){ state.brands.push(brand) },
    SET_ARCHIVED_BRANDS(state,brands){ state.archived = brands; },
    UPDATE_BRAND(state,brand){
        state.brands.forEach((item,i )=> {    
            if(item.id == brand.id)state.brands[i] = brand   
        })
    },
    DELETE_BRAND(state,brand){
        state.brands.forEach((item,i )=> {
            if(item.id == brand) {
                state.brands.splice(i, 1)  }
        })
    },
    DELETE_ARCHIVED_BRAND(state,brand){
        state.archived.forEach((item,i )=> {
            if(item.id == brand) {
                state.archived.splice(i, 1)  }
        })
    }

}